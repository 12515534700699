import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Modal,
  Table,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Art from "./Components/Art";

import { loadStripe } from "@stripe/stripe-js";

// firebase imports
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import * as helpers from "./helpers";

import axios from "axios";

/**
 * FIREBASE CONFIG DO NOT DELETE
 */
var firebaseConfig = {
  apiKey: "AIzaSyDSPoKNVmsacmW57cCmOF6RSgYLgRVwSF0",
  authDomain: "app.oasistab.com",
  databaseURL: "https://checkpointtab.firebaseio.com",
  projectId: "checkpointtab",
  storageBucket: "checkpointtab.appspot.com",
  messagingSenderId: "559388584512",
  appId: "1:559388584512:web:099edb0fba3e68f6364da5",
  measurementId: "G-4X290BX4N5",
};

const stripePromise = loadStripe(
  "pk_live_51Ha6VACDQw38kUIkTCCtqrankRCgPxvleQIyVLQQudamCMqclUAVaXszVycKngU4BZauqmMdnpGLwB8nLsExeBOO004tzsvWQx"
  // "pk_test_51Ha6VACDQw38kUIkDPIZATbqVS3PyIq3aKDYQ2UtckprsgLA59lUwehwduXK6zeHfJ32Sg8jUuT4sxcBrIEIlIfW00FNa65YDM"
);

axios.defaults.baseURL =
  "https://us-central1-checkpointtab.cloudfunctions.net/api";
// "http://localhost:5000/checkpointtab/us-central1/api";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

class App extends Component {
  constructor() {
    super();

    this.state = { redirecting: false, showPremiumPopup: false };
  }

  async componentDidMount() {
    document.body.style.background = "#F1ECE2";

    var queryParams = decodeURI(window.location.search)
      .replace("?", "")
      .split("&")
      .map((param) => param.split("="))
      .reduce((values, [key, value]) => {
        values[key] = value;
        return values;
      }, {});

    // decode sensitive params
    if (queryParams.isChrome) {
      this.redirectToStripe(
        queryParams.price,
        atob(queryParams.email),
        atob(queryParams.idToken)
      );
    }
  }

  Prices = {
    MONTHLY: "monthly",
    YEARLY: "yearly",
  };

  async redirectToStripe(price, email, idToken) {
    this.setState({ redirecting: true });

    // Get Stripe.js instance
    const stripe = await stripePromise;

    const createSessionPayload = {
      price: price,
    };

    // add coupon here for new growth marketer
    if (email && idToken) {
      if (helpers.validateEducation(email)) {
        createSessionPayload.coupon = "education";
      }
      axios.defaults.headers.common = {
        Authorization: `Bearer ${idToken}`,
      };
    }

    // Call your backend to create the Checkout Session
    const session = await axios.post(
      "/createCheckoutSession",
      createSessionPayload
    );
    console.log(session.data);

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.data.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  saveUserAfterSignInCallback = async (user) => {
    this.setState({ user, showPremiumPopup: true });
    var idToken = await user.getIdToken(false);
    this.setState({ idToken });
  };

  onSignInClick = async (providerName) => {
    await helpers.firebaseSignIn(
      providerName,
      this.saveUserAfterSignInCallback
    );
  };

  getCreateAccount() {
    return (
      <>
        <h3>Create an account:</h3>
        <p>
          Why do we ask to sign in?
          <br></br>
          <small>
            When you create an account, we're able to link your payment, so we
            know to give you premium when you download Oasis 🤗
          </small>
          <br></br>
          <br></br>
          <small>
            We never sell <i>any</i> information about you. We take your privacy
            seriously. See our{" "}
            <a
              href="https://oasistab.com/#legal"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            for more details
          </small>
        </p>
        <hr></hr>
        <Button onClick={() => this.onSignInClick("google")} variant="link">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/checkpointtab.appspot.com/o/btn_google_signin_dark_normal_web.png?alt=media&token=14393e0f-f72c-4dfd-91fc-efe80ce22bbc"
            alt="google"
          />
        </Button>
        &nbsp;&nbsp;
        <Button onClick={() => this.onSignInClick("microsoft")} variant="link">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/checkpointtab.appspot.com/o/btn-msft_signin_dark_normal_web.png?alt=media&token=b4544c3d-cfe0-4712-a650-f15034a23e23"
            alt="microsoft"
          />
        </Button>
        <p>
          <small>
            <em>Allow pop-ups to sign in with Google/Microsoft</em>
          </small>
        </p>
      </>
    );
  }

  handleClose = () => {
    this.setState({ showPremiumPopup: false });
  };

  render() {
    return (
      <Container
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Row>
          <Col xl={6}>
            <div>
              <h1>Hello 🤗</h1>
              <div className="left menu-text">
                <span>
                  Home&nbsp;❊&nbsp;Mood&nbsp;❊&nbsp;Programs&nbsp;❊&nbsp;Insights
                </span>
              </div>
              <br></br> <br></br>
              <h2> Thank you ❊ Your Oasis awaits you </h2>
            </div>
            <hr></hr>
            <div>
              {this.state.redirecting ? (
                <>
                  <Spinner size="md" animation="border" variant="dark" />{" "}
                  Redirecting ...
                </>
              ) : this.state.showPremiumPopup ? (
                <>
                  <Modal
                    show={this.state.showPremiumPopup}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header
                      style={{
                        fontFamily: "lato",
                        backgroundColor: "#F1ECE2",
                      }}
                      closeButton
                    >
                      <Modal.Title style={{ color: "#000d80" }}>
                        Get Oasis Premium 🤩
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        fontFamily: "lato",
                        backgroundColor: "#F1ECE2",
                      }}
                    >
                      <Table bordered striped>
                        <tbody>
                          <tr style={{ width: "50%" }}>
                            <td
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              Monthly
                              <br></br>
                              <span style={{ fontSize: "sm" }}>
                                <small>
                                  <small>Cancel anytime.</small>
                                </small>
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              Annual
                              <br></br>
                              <span style={{ fontSize: "sm" }}>
                                <small>
                                  <small>
                                    <b>Save 37% vs monthly.</b> Cancel anytime.
                                  </small>
                                </small>
                              </span>
                            </td>
                          </tr>
                          <tr style={{ width: "50%" }}>
                            <td>
                              <center>
                                <Button
                                  onClick={() =>
                                    this.redirectToStripe(
                                      this.Prices.MONTHLY,
                                      this.state.user.email,
                                      this.state.idToken
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#000d80",
                                    float: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  &nbsp;Monthly: $3.99/m
                                </Button>
                              </center>
                            </td>
                            <td>
                              <center>
                                <Button
                                  onClick={() =>
                                    this.redirectToStripe(
                                      this.Prices.YEARLY,
                                      this.state.user.email,
                                      this.state.idToken
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#000d80",
                                    float: "center",
                                  }}
                                >
                                  &nbsp;<b>Annual: $2.49/m</b>
                                </Button>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                this.getCreateAccount()
              )}
            </div>
          </Col>
          <Col
            xl={6}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Art style={{ justifyContent: "center", alignItems: "center" }} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default App;
