import React from "react";
import premiumTable from "../images/premiumTable.png";

function Art() {
  return (
    <div className="art">
      <img
        src={premiumTable}
        width="100%"
        height="89%"
        alt="premium-table"
      ></img>
      <br></br>
      <p style={{ fontFamily: "lato" }}>
        <strong>
          Any Questions about Oasis Premium? Email us at:{" "}
          <a href="mailto:hello@oasistab.com" target="_blank" rel="noreferrer">
            hello@oasistab.com
          </a>
        </strong>
      </p>
    </div>
  );
}

export default Art;
