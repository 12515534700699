import firebase from "firebase/app";

// based on the login button pressed for MSFT or GOOG
function getProvider(providerName) {
  var provider = "";
  if (providerName === "google") {
    provider = new firebase.auth.GoogleAuthProvider();
  } else if (providerName === "microsoft") {
    provider = new firebase.auth.OAuthProvider("microsoft.com");
  }

  return provider;
}

// when the firebase provider is selected, create a new user in auth table and in firebase `users` table that sets standard values
export function firebaseSignIn(providerName, saveUserAfterSignInCallback) {
  var provider = getProvider(providerName);

  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(async () => {
      try {
        const result = await firebase.auth().signInWithPopup(provider);

        // get the user doc
        const doc = firebase
          .firestore()
          .collection("users")
          .doc(result.user.email);

        doc
          .get()
          .then((docData) => {
            if (docData.exists) {
              // user exists
              saveUserAfterSignInCallback(result.user);
            } else {
              // this will create a user's table with the user based on their unique FB
              // auth UID as the doc.id, with displayName and email
              firebase
                .firestore()
                .collection("users")
                .doc(result.user.email)
                .set({
                  displayName: result.user.displayName,
                  email: result.user.email,
                  uid: result.user.uid,
                  subscription: "",
                  isPremium: false,
                  moodCount: 0,
                  breathCount: 0,
                  stretchCount: 0,
                  sessionCount: 0,
                  goals: "",
                  organization: "",
                  version: "",
                  shortName: "",
                })
                .catch((err) => {
                  console.log("something went wrong", err);
                });

              saveUserAfterSignInCallback(result.user);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error.code, error.message, error.email, error.credential);
      }
    });
}

// check if email is .edu and return true if educator, else false
export function validateEducation(email) {
  if (email.endsWith(".edu")) {
    return true;
  }
  return false;
}
